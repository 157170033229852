<template>
  <v-img
      :gradient="this.$vuetify.theme.dark ? 'to top, rgba(44,44,44, .85), rgba(55, 55, 55, .85)' : 'to top, rgba(44,44,44, .45), rgba(55, 55, 55, .45)'"
      min-height="calc(100vh - 66px - 64px)"
      src="../../assets/HirschWald.jpg"
  >
    <v-container class="mt-15">
      <v-sheet
          class="text-style"
          color="transparent"
      >
        <h1
            :class="$vuetify.breakpoint.mdAndUp ? 'display-2' : 'display-1'"
            class="mb-4 white--text"
            v-text="$t('home.hero.title')"
        />
        <p class="title font-weight-light white--text">
          {{ $t('home.hero.description') + " " + getVersion }}
        </p>
        <admin-menu v-if="$RStore.user.roles.Admin && !$RStore.user.roles.Moderator" />
        <lawyer-menu v-if="$RStore.user.roles.User" />
        <moderator-menu v-if="$RStore.user.roles.Moderator" />
      </v-sheet>
    </v-container>
  </v-img>
</template>

<script>
import AdminMenu from "@/views/menus/AdminMenu";
import LawyerMenu from "@/views/menus/LawyerMenu";
import ModeratorMenu from "@/views/menus/ModeratorMenu";
import {getVersion} from "@/configBuilder";
export default {
  name: "Hero",
  components: {ModeratorMenu, LawyerMenu, AdminMenu},
  data: () => ({
  }),
  computed: {
    getVersion() {
      return getVersion()
    },
    getLabel() {
      return JSON.parse(JSON.stringify(this.$t('home.hero.selectRoute')));
    },
  }
}
</script>

<style lang="stylus" scoped>

</style>
