<template>
  <div>
    <v-row>
      <v-col cols="4" v-if="isLoading">
        <v-skeleton-loader type="card" ></v-skeleton-loader>
      </v-col>
      <v-col cols="4" v v-if="isLoading">
        <v-skeleton-loader type="card" ></v-skeleton-loader>
      </v-col>
      <v-col cols="4" v-if="isLoading">
      <v-skeleton-loader type="card" ></v-skeleton-loader>
    </v-col>
      <v-col cols="auto" v-for="insurance in finalInsurances" v-bind:key="insurance.name">
        <v-card shaped :width="$vuetify.breakpoint.smAndDown ? '': '670px'" class="fill-height" :color="$vuetify.theme.dark ? 'rgba(30,30,30,0.8)' : 'rgba(255,255,255,0.9)'" flat >
          <v-row class="mb-3 mr-2 ml-2">
            <v-col cols="8">
              <v-card-title>
                {{ $tc('home.hero.lawyerMenu.forms', insurance.forms.length) }}
              </v-card-title>
              <v-card-actions>
                <v-row>
                  <v-col v-for="route in insurance.forms" v-bind:key="route.name" cols="auto">
                    <v-btn color="primary" :small="$vuetify.breakpoint.mdAndDown" :to="route.route"> {{
                        route.name
                      }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
            <v-col cols="4">
              <v-img
                  elevation="15"
                  :src="require('@/assets/insuranceLogos/' + insurance.src)"
                  :alt="insurance.name + ' Logo'"
                  class="white--text align-end"
                  height="150"
                  contain
              >
              </v-img>
            </v-col>
            <v-col  cols="auto">
              <v-card-title v-if="insurance.routes.length > 0">   {{ insurance.name === "MPU" ? "" : "Unzugewiesene" }}  {{ insurance.name }} Fälle</v-card-title>
              <v-card-actions v-if="insurance.routes.length > 0">
              <v-row>
                  <v-col v-for="(route) in insurance.routes" v-bind:key="route.name" cols="auto">
                    <v-badge
                        :color="route.counter > 0 ? 'secondary' : ''"
                        overlap
                        :content="route.counter"
                    >
                      <v-btn :small="$vuetify.breakpoint.mdAndDown" color="primary" :to="route.route">
                        <v-icon left>mdi-{{ route.icon }}</v-icon>
                        {{ route.name }}
                      </v-btn>
                    </v-badge>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-10">
      <v-col cols="12" md="auto">
        <v-btn dark  color="primary"  to="export">
          <v-icon left>mdi-export</v-icon>
          Export
        </v-btn>
      </v-col>
      <v-col cols="12" md="auto">
        <v-btn dark color="primary" to="calendar">
          <v-icon left>mdi-calendar</v-icon>
          Kalender
        </v-btn>
      </v-col>
      <v-col cols="12" md="auto">
        <v-btn dark  color="primary" to="bill">
          <v-icon left>mdi-receipt-text</v-icon>
          Rechnungen
        </v-btn>
      </v-col>
      <v-col cols="12" md="auto">
        <v-btn dark  color="primary" to="permissions">
          <v-icon left>mdi-security</v-icon>
          Berechtigungen
        </v-btn>
      </v-col>
      <!--
      <v-col cols="12" md="auto">
        <v-btn dark  color="error" to="statistics">
          <v-icon left>mdi-chart-bar</v-icon>
          Statistik (in Arbeit)
        </v-btn>
      </v-col>
      -->
    </v-row>
  </div>
</template>

<script>
import {sessionHandler} from "@/request/sessionHandler";
import {getApiURL, requestMode} from "@/configBuilder";

export default {
  name: "AdminMenu",
  data: () => ({
    doneWith: 0,
    isLoading: false,
    current: [],
    finalInsurances: [],
    insurances: [
      {name: "ARAG", src: "ARAG_Logo.png", forms:
            [{name: "Vertragscheck erstellen", route: "/admin/aragVertragscheck", icon:"file-document", news: 0},{name: "Onlineberatung erstellen", route: "/admin/aragOnlineBeratung", icon:"file-document", news: 0}, {name: "Manager Rechtsschutz erstellen", route: "/admin/aragManagerRechtsschutz", icon:"file-document", news: 0}],
        routes: [
          {
            name: "Dokumentenprüfung",
            route: "/arag/Dokumentenpruefung",
            icon: "file-document",
            dataType: "Dokumentenpruefung",
            counter: 0
          },
          {
            name: "Vertragschecks",
            route: "/arag/Vertragscheck",
            icon: "file-document",
            dataType: "Vertragscheck",
            counter: 0
          },
          {
            name: "Manager Rechtsschutz",
            route: "/arag/ManagerRechtsschutz",
            icon: "file-document",
            dataType: "ManagerRechtsschutz",
            counter: 0
          },
          {
            name: "Erstberatung Versicherungspruefung",
            route: "/arag/ErstberatungVersicherungspruefung",
            icon: "file-document",
            dataType: "ErstberatungVersicherungspruefung",
            counter: 0
          },
          {
            name: "Online Beratungen",
            route: "/arag/OnlineBeratung",
            icon: "phone",
            dataType: "OnlineBeratung",
            counter: 0
          },
          {
            name: "Prüfung Anstellungsvertrag",
            route: "/arag/PruefungAnstellungsvertrag",
            icon: "file-document",
            dataType: "PruefungAnstellungsvertrag",
            counter: 0
          }
        ]},
      {name: "Advocard", src: "Advocard_Logo.png", forms: [] ,routes: [
          {
          name: "Dokumenten Checks",
          route: "/advocardPrivate/Dokumentencheck",
          icon: "file-document",
          dataType: "Dokumentencheck",
          counter: 0
        },
          {
          name: "Mediationen",
          route: "/advocardPrivate/Mediation",
          icon: "handshake",
          dataType: "Mediation",
          counter: 0
        },
          {
            name: "Coaching",
            route: "/advocardPrivate/Coaching",
            icon: "human-male-board",
            dataType: "Coaching",
            counter: 0
          },
          {
            name: "Fachberatung",
            route: "/advocardPrivate/Fachberatung",
            icon: "account-hard-hat",
            dataType: "Fachberatung",
            counter: 0
          }
        ]},
      // {name: "Roland", src: "Roland_Logo.svg", forms: [{name: "Roland", route: "roland"}]},
      {name: "MPU", src: "MPU_Logo.jpg", forms: [{name: "MPU", route: "/form/mpu"}], routes: [{name: "MPU Fälle", route: "/MPU/mpu",    icon: "file-document",    counter: 1,  dataType: "MPUCase",}]},
    ],
  }),
  methods: {
    addOpenCaseCounter() {
      this.insurances.forEach(insurance => {
        this.doneWith = this.doneWith + insurance.routes.length
        insurance.routes.forEach(route => {
          let controllerURL = route.route.split("/")[1][0].toUpperCase() + route.route.split("/")[1].substring(1, route.route.split("/")[1].length) + "Data"
          this.getUnassignedCases(controllerURL, route.route.split("/")[1], route.dataType).then(unassignedCases => {
            route.counter = unassignedCases.length
            this.current.push(true)
            // checking for advocardInternational as well cuz they are in the same /cases route
            if (route.route.split("/")[1] === "advocardPrivate"){
              // only if not Fachberatung cuz it has no Fachberatung
              if(route.dataType !== "Fachberatung") {
                this.doneWith = this.doneWith + 1
                this.getUnassignedCases("AdvocardInternationalData", "advocardInternational", route.dataType).then(openCases => {
                  route.counter = route.counter + openCases.length
                  this.current.push(true)
                })
              }
            }
          })
        })
      })
    },
    async getUnassignedCases(controllerURL, path, dataType){
      this.isLoading = true
      let parameter = {
        [ path + "DataType"]: dataType,
      }
      await sessionHandler();
      return new Promise((resolve, reject) => {
        this.$http
            .get(getApiURL(controllerURL), {
              mode: requestMode(),
              params: parameter,
              headers: {
                'Accept': 'application/json',
                'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
              },
            })
            .then((response) => {
              if(dataType === "MPUCase"){
                return resolve(response.data);
              } else {
                return resolve(response.data.filter(unassignedCase => unassignedCase.createdBy === null));
              }
            })
            .catch((error) => {
              return reject(error);
            });
      });
    },
    filterRoutes(){
      this.insurances.forEach(insurance => {
        insurance.routes = insurance.routes.filter(route => route.counter > 0)
      })
      this.finalInsurances = this.insurances;
      this.isLoading = false
    }
  },
  mounted() {
    this.addOpenCaseCounter()
  },
  watch: {
    current(){
      if (this.doneWith === this.current.length){
        this.filterRoutes()
      }
    }
  }
}
</script>

<style scoped>

</style>
