<template>
  <div>
    <v-row>
      <v-col v-if="isLoading" cols="auto">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-col>
      <v-col v-for="insurance in insurances" v-bind:key="insurance.name" cols="auto">
        <v-card :color="$vuetify.theme.dark ? 'rgba(30,30,30,0.8)' : 'rgba(255,255,255,0.9)'" :width="$vuetify.breakpoint.smAndDown ? '': '700px'" class="fill-height"
                flat shaped>
          <v-row class="mb-3 mr-2 ml-2">
            <v-col cols="8">
              <v-card-title v-if="insurance.forms ? insurance.forms.length > 0 : false">
                {{ $tc('home.hero.lawyerMenu.forms', insurance.forms.length) }}
              </v-card-title>
              <v-card-actions>
                <v-row>
                  <v-col v-for="route in insurance.forms" v-bind:key="route.name" cols="auto">
                    <v-btn :small="$vuetify.breakpoint.mdAndDown" :to="route.route" color="primary"> {{
                        route.name
                      }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
            <v-col cols="4">
              <v-img
                  :alt="insurance.name + ' Logo'"
                  :src="require('@/assets/insuranceLogos/' + insurance.src)"
                  :style="$vuetify.theme.dark ? '': insurance.style "
                  class="white--text align-end"
                  contain
                  elevation="15"
                  height="170"
              >
              </v-img>
            </v-col>
            <v-col cols="12">
              <v-card-title v-if="insurance.routes ? insurance.routes.length > 0 : false ">
                {{ $tc('home.hero.lawyerMenu.masks', insurance.routes.length) }}
              </v-card-title>
              <v-card-actions>
                <v-row>
                  <v-col v-for="route in insurance.routes" v-bind:key="route.name" cols="auto">
                    <v-badge
                        :color="route.counter > 0 ? 'secondary' : ''"
                        :content="route.counter"
                        overlap
                    >
                      <v-btn :small="$vuetify.breakpoint.mdAndDown" :to="route.route" color="primary">
                        <v-icon left>mdi-{{ route.icon }}</v-icon>
                        {{ route.name }}
                      </v-btn>
                    </v-badge>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>

        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-10">
      <v-col cols="12" md="auto">
        <v-btn :small="$vuetify.breakpoint.mdAndDown" color="primary" dark to="export">
          <v-icon left>mdi-export</v-icon>
          Export
        </v-btn>
      </v-col>
      <v-col cols="12" md="auto">
        <v-btn :small="$vuetify.breakpoint.mdAndDown" color="primary" dark to="calendar">
          <v-icon left>mdi-calendar</v-icon>
          Kalender
        </v-btn>
      </v-col>
      <v-col cols="12" md="auto">
        <v-btn :small="$vuetify.breakpoint.mdAndDown" color="primary" dark to="unassignedMails">
          <v-icon left>mdi-mail</v-icon>
          Nicht adressierbare E-Mails
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import reactiveStorage from "@/plugins/reactiveStorage";
import {sessionHandler} from "@/request/sessionHandler";
import {getApiURL, requestMode} from "@/configBuilder";

export default {
  name: "LawyerMenu",
  data: () => ({
    insurances: [
      {
        name: "ARAG", src: "ARAG_Logo.png",
        caseCountKey: "caseCountARAG",
        forms: [
          {
            name: "Privat",
            route: "arag",
            permission: reactiveStorage.user.permissions.insurance.aragDataType.Telefonberatung
          },
          {
            name: "Erstberatung mit Dokumentenprüfung ",
            route: "aragErstberatung",
            permission: reactiveStorage.user.permissions.insurance.aragDataType.Dokumentenpruefung
          },
          {
            name: "Erstberatung Versicherungsprüfung",
            route: "aragVersicherungspruefung",
            permission: reactiveStorage.user.permissions.insurance.aragDataType.ErstberatungVersicherungspruefungVermittlung
          },
          {
            name: "Strafrechtshotline",
            route: "aragStrafrechtshotline",
            permission: reactiveStorage.user.permissions.insurance.aragDataType.Strafrechtshotline
          },
          {
            name: "Prüfung Anstellungsvertrag",
            route: "aragPruefungAnstellungsvertrag",
            permission: reactiveStorage.user.permissions.insurance.aragDataType.PruefungAnstellungsvertragVermittlung
          }
        ],
        routes: [
          {
            name: "Dokumentenprüfung",
            route: "/arag/Dokumentenpruefung",
            icon: "file-document",
            dataType: "Dokumentenpruefung",
            permission: reactiveStorage.user.permissions.insurance.aragDataType.Dokumentenpruefung,
            counter: 0
          },
          {
            name: "Vertragschecks",
            route: "/arag/Vertragscheck",
            icon: "file-document",
            dataType: "Vertragscheck",
            permission: reactiveStorage.user.permissions.insurance.aragDataType.Vertragscheck,
            counter: 0
          },
          {
            name: "Manager Rechtsschutz",
            route: "/arag/ManagerRechtsschutz",
            icon: "file-document",
            dataType: "ManagerRechtsschutz",
            permission: reactiveStorage.user.permissions.insurance.aragDataType.ManagerRechtsschutz,
            counter: 0
          },
          {
            name: "Erstberatung Versicherungspruefung",
            route: "/arag/ErstberatungVersicherungspruefung",
            icon: "file-document",
            dataType: "ErstberatungVersicherungspruefung",
            permission: reactiveStorage.user.permissions.insurance.aragDataType.ErstberatungVersicherungspruefung || reactiveStorage.user.permissions.insurance.aragDataType.ErstberatungVersicherungspruefungFallback,
            counter: 0
          },
          {
            name: "Online Beratungen",
            route: "/arag/OnlineBeratung",
            icon: "phone",
            dataType: "OnlineBeratung",
            permission: reactiveStorage.user.permissions.insurance.aragDataType.OnlineBeratung,
            counter: 0
          },
          {
            name: "Prüfung Anstellungsvertrag",
            route: "/arag/PruefungAnstellungsvertrag",
            icon: "file-document",
            dataType: "PruefungAnstellungsvertrag",
            permission: reactiveStorage.user.permissions.insurance.aragDataType.PruefungAnstellungsvertrag || reactiveStorage.user.permissions.insurance.aragDataType.PruefungAnstellungsvertragVermittlung,
            counter: 0
          }
        ]
      },
      {
        name: "Advocard",
        src: "Advocard_Logo.png",
        caseCountKey: "caseCountAdvocard",
        forms: [
          {
            name: "Privat",
            route: "advocardPrivate",
            permission: reactiveStorage.user.permissions.insurance.advocardPrivateDataType.Telefonberatung
          }, {
            name: "Business",
            route: "advocardBusiness",
            permission: reactiveStorage.user.permissions.insurance.advocardBusinessDataType.Telefonberatung
          }, {
            name: "International",
            route: "advocardInternational",
            permission: reactiveStorage.user.permissions.insurance.advocardInternationalDataType.Telefonberatung
          },],
        routes: [{
          name: "Dokumenten Checks",
          route: "/advocardPrivate/Dokumentencheck",
          icon: "file-document",
          dataType: "Dokumentencheck",
          permission: reactiveStorage.user.permissions.insurance.advocardPrivateDataType.Dokumentencheck || reactiveStorage.user.permissions.insurance.advocardInternationalDataType.Dokumentencheck,
          counter: 0
        }, {
          name: "Mediationen",
          route: "/advocardPrivate/Mediation",
          icon: "handshake",
          dataType: "Mediation",
          permission: reactiveStorage.user.permissions.insurance.advocardPrivateDataType.Mediation || reactiveStorage.user.permissions.insurance.advocardInternationalDataType.Mediation,
          counter: 0
        },
          {
            name: "Coachings",
            route: "/advocardPrivate/Coaching",
            icon: "human-male-board",
            dataType: "Coaching",
            permission: reactiveStorage.user.permissions.insurance.advocardPrivateDataType.Coaching || reactiveStorage.user.permissions.insurance.advocardInternationalDataType.Coaching,
            counter: 0
          }, {
            name: "Fachberatungen",
            route: "/advocardPrivate/Fachberatung",
            icon: "account-hard-hat",
            dataType: "Fachberatung",
            permission: reactiveStorage.user.permissions.insurance.advocardPrivateDataType.Fachberatung,
            counter: 0
          },

        ]
      },
      {
        name: "Roland",
        src: "Roland_Logo.svg",
        caseCountKey: "caseCountRoland",
        style: "-webkit-filter: invert(.99); /* safari 6.0 - 9.0 */ filter: invert(.99);",
        forms: [{
          name: "Privat",
          route: "roland",
          permission: reactiveStorage.user.permissions.insurance.rolandDataType.Telefonberatung
        }]
      },
      {
        name: "MPU",
        src: "MPU_Logo.jpg",
        caseCountKey: "caseCountMPU",
        forms: [/* {name: "MPU", route: "/form/mpu",  permission: reactiveStorage.user.permissions.MPU.MPU} */],
        routes: [{
          name: "MPU Fälle",
          route: "/MPU/mpu",
          icon: "file-document",
          counter: 0,
          permission: reactiveStorage.user.permissions.MPU.MPU,
          dataType: "MPU",
        }]
      },
    ],
    routeSelected: {},
    isLoading: false,
  }),
  methods: {
    filterPermission(arr) {
      return arr.filter((item) => {
        return item.permission
      })
    },
    hasViewPermission(array) {
      array.forEach((arr, index) => {
        arr.forms = arr.forms ? this.filterPermission(arr.forms) : []
        arr.routes = arr.routes ? this.filterPermission(arr.routes) : []
        if (arr.forms.length === 0 && arr.routes.length === 0) {
          array.splice(index, 1);
          this.hasViewPermission(array)
        }
      })
      return array
    },
    addOpenCaseCounter() {
      this.isLoading = true
      this.getOpenCaseCount().then(openCases => {
        this.insurances.forEach(insurance => {
          insurance.routes.forEach(route => {
            if (insurance.caseCountKey === "caseCountAdvocard") {
              if (route.dataType !== "Fachberatung") {
                route.counter = openCases[insurance.caseCountKey + "Private"][route.dataType] + openCases[insurance.caseCountKey + "International"][route.dataType]
              } else {
                route.counter = openCases[insurance.caseCountKey + "Private"][route.dataType]
              }
            } else {
              route.counter = openCases[insurance.caseCountKey][route.dataType]
            }
          })
        })
      }).finally(() => {
        this.isLoading = false
      })
    },
    async getOpenCaseCount() {
      await sessionHandler();
      return new Promise((resolve, reject) => {
        this.$http
            .get(getApiURL("Statistics/OwnOpenCaseCount"), {
              mode: requestMode(),
              headers: {
                'Accept': 'application/json',
                'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
              },
            })
            .then((response) => {
              return resolve(response.data);
            })
            .catch((error) => {
              return reject(error);
            });
      });
    },
    async getOpenCases(controllerURL, path, dataType) {
      // this.isLoading = true
      let parameter = {
        [path + "DataType"]: dataType,
        "isClosed": "false",
      }
      // console.log("dT", dataType)
      const urlAddon = dataType === "MPU" ? "" : "/Own"
      await sessionHandler();
      return new Promise((resolve, reject) => {
        this.$http
            .get(getApiURL(controllerURL) + urlAddon, {
              mode: requestMode(),
              params: parameter,
              headers: {
                'Accept': 'application/json',
                'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
              },
            })
            .then((response) => {
              return resolve(response.data);
            })
            .catch((error) => {
              return reject(error);
            });
      });
    },
  },
  mounted() {
    this.insurances = this.hasViewPermission(this.insurances)
    this.addOpenCaseCounter()
  },
  computed: {
    LawyerMenu() {
      return this.$t("hero.lawyerMenu");
    },
    getLabel() {
      return JSON.parse(JSON.stringify(this.$t('home.hero.selectRoute')));
    },
  }
}
</script>

<style scoped>

</style>
